<script setup>
  import { reactive } from 'vue'

  const days = reactive({
    start: '2022年9月1日(木)',
    end: '2022年10月31日(月)'
  })
</script>

<template>
  <section id="campaign" class="campaign">
    <div class="wrap">
      <h2>
        ＼ ぜひ期間内にお申込みください ／
        <span class="other-font" v-text="'GA4構築お急ぎ便'" />
      </h2>
      <div class="campaign-content">
        <span class="campaign-label other-font" v-text="'キャンペーン実施中'" />
        <div class="campaign-body">
          <div class="campaign-body-left">
            <p class="campaign-body-left__upper">
              弊社の定める業務範囲で、<br />
              期間内に構築が完了しなかった場合
            </p>
            <p class="campaign-body-left__under" v-text="'構築費用がなんと'" />
          </div>
          <p class="campaign-body-right">0<span>円</span></p>
        </div>
        <p class="campaign-days">
          キャンペーン期間：<br class="sp" />{{ days.start }} 〜 {{ days.end }}まで
        </p>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .campaign {
    padding-bottom: 100px;
    @include sp {
      padding-bottom: 50px;
    }
    h2 {
      font-size: 22px;
      margin-bottom: 12px;
      font-weight: normal;
      line-height: 1;
      @include tb {
        font-size: 3vw;
      }
      @include sp {
        font-size: 16px;
      }
      span {
        display: block;
        font-weight: bold;
        font-size: 50px;
        margin-top: 16px;
        @include tb {
          font-size: 6vw;
        }
        @include sp {
          font-size: 34px;
          margin-top: 20px;
        }
      }
    }
    &-content {
      text-align: center;
      margin-top: 20px;
    }
    &-label {
      display: inline-block;
      padding: 0 45px;
      font-size: 78px;
      line-height: 1.6;
      font-weight: bold;
      background-color: $colorOrange;
      color: #FFF;
      @include tb {
        font-size: 8.4vw;
        padding: 0 30px;
      }
      @include sp {
        display: block;
        font-size: 9vw;
        text-align: center;
        padding: 5px 0;
      }
    }
    &-body {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      @include sp {
        flex-direction: column;
        margin-top: 20px;
      }
      &-left {
        margin: auto 20px 0 0;
        &__upper,
        &__under {
          margin: 0;
          text-align: left;
          @include sp {
            text-align: center;
          }
        }
        &__upper {
          font-size: 22px;
          line-height: 1.6;
          @include tb {
            font-size: 2.4vw;
          }
          @include sp {
            font-size: 5vw;
          }
        }
        &__under {
          font-weight: bold;
          font-size: 54px;
          margin-top: 4px;
          @include tb {
            font-size: 5.8vw;
          }
          @include sp {
            font-size: 10vw;
            margin-top: 10px;
          }
        }
      }
      &-right {
        display: flex;
        margin: 0;
        font-size: 240px;
        font-weight: bold;
        color: $colorRed;
        line-height: 1;
        align-items: baseline;
        @include tb {
          font-size: 22.8vw;
        }
        @include sp {
          display: block;
        }
        span {
          font-size: 80px;
          @include tb {
            font-size: 7.6vw;
          }
        }
      }
    }
    &-days {
      font-size: 24px;
      font-weight: bold;
      margin-top: 50px;
      @include tb {
        font-size: 2.6vw;
      }
      @include sp {
        font-size: 3.6vw;
        line-height: 1.6;
      }
    }
  }

</style>