<script setup>
  import { ref } from 'vue'

  const list = ref([
    'Googleアナリティクス4に<br />移行したいが最適な<br />設定・設計方法が分からない',
    'Googleアナリティクス4を<br />導入・活用してみたいが社内で<br />習得するリソースがない',
    '自分たちで移行作業を<br />行ってみたものの適切に<br />設定されているかが分からない'
  ])
</script>

<template>
  <section id="trouble" class="trouble">
    <div class="wrap">
      <h2>しかし、自分たちで設定<br class="sp" />するのは難しい...</h2>
      <div class="trouble-contents">
        <div v-for="(l, i) in list" class="trouble-content" :key="i">
          <div class="trouble-content__img"><img :src="require(`@/assets/img/but_illustration0${i+1}.png`)" /></div>
          <p class="trouble-content__text" v-html="l" />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .trouble {
    padding-bottom: 100px;
    background-color: #F3E2DA;
    position: relative;
    @include sp {
      padding-bottom: 50px;
    }
    h2 {
      margin-bottom: 50px;
      @include sp {
        line-height: 1.6;
      }
    }
    &-contents {
      display: flex;
      justify-content: center;
      gap: 35px;
      @include middleDevice {
        flex-direction: column;
        gap: 20px 0;
      }
      @include sp {
        flex-direction: column;
        gap: 20px 0;
      }
    }
    &-content {
      width: calc(100% / 3);
      background-color: #FFF;
      padding: 40px 30px 140px;
      display: flex;
      align-items: center;
      position: relative;
      @include middleDevice {
        width: 100%;
        padding: 50px 50px 140px;
      }
      @include sp {
        width: 100%;
        padding: 50px 50px 140px;
      }
      &__img {
        flex: 1;
        img {
          width: 100%;
        }
      }
      &__text {
        position: absolute;
        bottom: 40px;
        left: 30px;
        width: calc(100% - 60px);
        margin: 0;
        text-align: center;
        line-height: 1.6;
        font-size: 16px;
        font-weight: bold;
        @include tb {
          left: 0;
          width: 100%;
          font-size: 15px;
        }
      }
      &:nth-of-type(3) {
        padding-left: 50px;
        padding-right: 50px;
        @include middleDevice {
          padding-left: 70px;
          padding-right: 70px;
        }
        @include sp {
          padding-left: 70px;
          padding-right: 70px;
        }
      }
    }
    &::after {
      content: '';
      position: absolute;
      bottom: -40px;
      left: 50%;
      transform: translateX(-50%);
      display: inline-block;
      border-style: solid;
      border-width: 40px 50px 0 50px;
      border-color: #F3E2DA transparent transparent transparent;
    }
  }

</style>
