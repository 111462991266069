<script setup>
  import { reactive } from 'vue'
  import ScrollHint from 'scroll-hint';

  window.addEventListener('load', () => {
    new ScrollHint('.js-scrollable');
  })

  const table = reactive({
    head: ['項目', 'UA', 'GA4'],
    body: [
      ['計測対象', 'Webサイト', 'Webサイトとアプリ', '両者を統合して分析できる'],
      ['レポートモデル', 'セッションスコープのレポート', 'ユーザースコープのライフサイクルレポート', 'セッション軸からユーザー軸へ'],
      ['機械学習', 'なし', 'あり', '予測をもとに広告活用が可能に<br /><span>(現状は一部のECサイトのみで利用可能)</span>'],
      ['BigQuery連携', '有償版のみ', '無償版でも利用可能', 'より広範なデータ活用が可能に'],
      ['ユーザー識別', '基本的にCookie', 'User ID + Googleシグナル + Cookie', '活用には会員登録が重要に'],
    ]
  })
</script>

<template>
  <section id="change" class="change">
    <div class="wrap">
      <h2>＼ GA4導入の5つの変更点 ／</h2>
      <div class="js-scrollable">
        <table class="table">
          <thead>
            <tr>
              <th v-for="head in table.head" :key="head" v-text="head" />
            </tr>
          </thead>
          <tbody>
            <tr v-for="(body, i) in table.body" :key="i">
              <td v-text="body[0]" />
              <td v-text="body[1]" />
              <td>
                {{ body[2] }}
                <div class="table-arrow" v-html="body[3]" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="change-notes">
        <span>旧Googleアナリティクス = UA</span>
        <span>Googleアナリティクス4 = GA4</span>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .change {
    padding-bottom: 100px;
    @include sp {
      padding-bottom: 50px;
    }
    .js-scrollable {
      overflow: scroll;
    }
    .table {
      border-collapse: collapse;
      width: 1000px;
      thead {
        th {
          background-color: $colorYellow;
          font-weight: bold;
          font-size: 24px;
          border-right: 1px solid #FFF;
          text-align: center;
          width: 250px;
          &:not(:first-of-type):not(:last-of-type) {
            width: 300px;
          }
          &:last-of-type {
            background-color: $colorOrange;
            color: #FFF;
            font-size: 36px;
            border-right: none;
            padding: 12px 10px 16px;
            width: 450px;
          }
        }
      }
      tbody {
        td {
          background-color: #F6F2EC;
          border-top: #FFF solid 1px;
          border-right: 1px solid #FFF;
          padding: 30px 40px;
          text-align: center;
          &:not(:nth-of-type(2)) {
            font-weight: bold;
          }
          &:last-of-type {
            padding-top: 15px;
            padding-bottom: 15px;
            text-align: left;
            font-size: 15px;
            background-color: #FBE7D8;
          }
          .table-arrow {
            margin-top: 8px;
            padding-left: 35px;
            position: relative;
            font-size: 18px;
            color: $colorOrange;
            :deep(span) {
              font-size: 11px;
            }
            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              display: inline-block;
              width: 23px;
              height: 23px;
              background-color: $colorBlack;
              clip-path: polygon(0 33%, 50% 33%, 50% 15%, 100% 50%, 50% 85%, 50% 67%, 0 67%);
            }
          }
        }
      }
    }
    &-notes {
      margin-top: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include sp {
        margin-top: 25px;
        flex-direction: column;
        line-height: 1.6;
      }
      span {
        font-weight: bold;
        &:first-of-type {
          margin-right: 30px;
          @include sp {
            margin: 0;
          }
        }
        &:last-of-type {
          font-size: 20px;
          color: $colorOrange;
          @include sp {
            margin-top: 10px;
          }
        }
      }
    }
  }

</style>
