<script setup>
  import { reactive, computed } from 'vue'

  const list = reactive({
    title: [
      'GA4の設定をしたら、UAで計測が止まってしまった or 2重計測されてしまった...',
      'コンバージョン設定の方法を間違えて正しく計測されなかった…',
      'eコマース設定がおかしくなってしまった…'
    ],
    body: [
      'GA4のデータが十分に溜まるまでの間は引き続きUAを使う必要があります。しかし、GA4設定時のミスにより、UAに悪影響が出てしまうこともしばしば。計測がピタリと止まってしまったケースや、逆にPV数が突然2倍になってしまったケースも。誤計測されてしまったものは元に戻せません。',
      'Analyticsで解析をする上で、コンバージョン設定は必要不可欠。しかし、GA4での設定方法は、UAとは仕様も概念も異なります。そもそも計測されなかったケースや、計測された数値が実際と異なっていたケースも。誤計測されてしまったものは元に戻せません。',
      'あなたのご担当がECサイトであれば、Analyticsのeコマース設定は最重要。しかし、GA4では計測仕様が更新されており、UAでの設定のままだと正しく計測されません。移行に必要な作業にはいくつもの落し穴があり、問題なく遂行するのは至難の業です。'
    ]
  })

  const getUpperCase = computed(() => (i) => {
    switch (i) {
      case 1: return '１'
      case 2: return '２'
      case 3: return '３'
      default: return ''
    }
  })
</script>

<template>
  <section id="case" class="case">
    <div class="wrap">
      <h2>自分たちで導入を進めた結果、<br class="sp" />こんな失敗事例も...！</h2>
      <div class="case-contents">
        <div v-for="(title, i) in list.title" class="case-content" :key="i">
          <span class="label" v-text="`失敗事例${getUpperCase(i+1)}`" />
          <h3 v-text="title" />
          <p v-text="list.body[i]" />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .case {
    padding-bottom: 100px;
    @include sp {
      padding-bottom: 50px;
      padding-top: 100px;
    }
    h2 {
      margin-bottom: 100px;
      @include sp {
        margin-bottom: 50px;
        line-height: 1.6;
      }
    }
    &-content {
      border: $colorBlack solid 6px;
      margin-top: 50px;
      padding: 50px 30px;
      position: relative;
      @include sp {
        padding: 40px 30px 20px;
      }
      h3 {
        margin: 0 0 15px;
        color: $colorOrange;
        font-size: 24px;
        @include tb {
          line-height: 1.6;
        }
        @include sp {
          font-size: 22px;
          line-height: 1.6;
        }
      }
      p {
        margin: 0;
        font-size: 16px;
        line-height: 1.6;
        @include sp {
          font-size: 14px;
        }
      }
      .label {
        display: block;
        position: absolute;
        top: -20px;
        left: -21px;
        width: 120px;
        font-size: 16px;
        padding: 10px 0;
        color: #FFF;
        background-color: $colorOrange;
        font-weight: bold;
        text-align: center;
        @include sp {
          left: -15px;
        }
        &::before {
          position: absolute;
          content: '';
          top: 100%;
          left: 0;
          border: none;
          border-bottom: solid 12px transparent;
          border-right: solid 15px $colorRed;
          @include sp {
            border-bottom-width: 7px;
            border-right-width: 9px;
          }
        }
      }
    }
  }

</style>
