<script setup>
  import { reactive } from 'vue'

  const list = reactive({
    title: ['納品までどのくらいの時間がかかりますか？', 'GA4を導入した後、自分たちで活用できるか不安です', '自分たちでGA4の設定をしてみたが、数値が正しいか不安です'],
    body: [
      'お客様のWebサイトの規模や解析対象とするデータの内容によって変動しますが、作業開始から平均3〜5営業日程度での納品が可能です。',
      '担当させていただく弊社スタッフは、日常業務でGA4を用いたWebサイトの解析をしています。<br />そのノウハウをもとに手厚くサポートさせていただきますので、ご安心ください。',
      '既存の設定内容を確認させていただき、修正が必要であれば弊社が責任を持って対応いたします。<br />まずはお気軽にご相談ください。'
    ]
  })
</script>

<template>
  <section id="question" class="question">
    <div class="wrap">
      <h2 v-text="'よくあるご質問'" />
      <div class="question-list">
        <template v-for="(title, i) in list.title" :key="i">
          <dl>
            <dt v-text="title" />
            <dd v-html="list.body[i]" />
          </dl>
        </template>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .question {
    padding-bottom: 100px;
    @include sp {
      padding-bottom: 50px;
    }
    &-list {
      dt {
        margin-top: 60px;
        font-size: 24px;
        color: $colorOrange;
        font-weight: bold;
        position: relative;
        line-height: 1.6;
        padding: 0 0 0 24px;
        @include sp {
          margin-top: 50px;
          font-size: 18px;
        }
        &::before {
          content: '';
          height: 100%;
          width: 8px;
          position: absolute;
          left: 0;
          top: 0;
          background-color: $colorOrange;
        }
        &::after {
          position: absolute;
          bottom: -4px;
          left: 18px;
          content: '';
          width: 100%;
          height: 2px;
          background: $colorOrange;
          transform: scale(0, 1);
          transform-origin: left top;
          transition: transform .3s;
        }
      }
      dd {
        padding: 0 0 0 24px;
        font-size: 16px;
        line-height: 1.6;
        margin: 15px 0 0;
        @include sp {
          font-size: 14px;
          margin-top: 10px;
        }
      }
      dl:hover dt::after {
        transform: scale(1, 1);
      }
    }
  }

</style>