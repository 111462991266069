<script setup>
</script>

<template>
  <section id="headsup" class="headsup">
    <div class="wrap">
      <h2>
        <span class="border">Googleアナリティクスをご利用のあなた！</span><br />
        <span class="border big">お急ぎください！</span>
      </h2>
      <div class="what">
        <div class="text">
          <p class="text-upper">
            旧バージョンの「ユニバーサルアナリティクス」は、<br />
            <span class="border big"><span class="red">2023年7月1日</span>に計測が停止します</span>
          </p>
          <p class="text-under">
            新バージョンの「Googleアナリティクス4」への移行が必要です！<br />
            今すぐ移行を進めないと...
          </p>
          <ul>
            <li>Webサイトの<span class="red">データが計測</span>できなくなる</li>
            <li>データを基にしたWebサイトの<span class="red">分析・改善</span>ができなくなる</li>
            <li><span class="red">売上が下がって</span>しまう</li>
          </ul>
        </div>
        <div class="image">
          <img src="@/assets/img/headsup_what_illustration.png" />
        </div>
      </div>
      <div class="alert">
        <p class="alert-title">UAの管理画面上に<br class="sp" />以下のような<span class="red">アラートが<br class="sp" />出ていたら要注意</span>です！</p>
        <div class="alert-img">
          <img src="@/assets/img/headsup_analytics.jpg" />
        </div>
        <p class="alert-notes">
          「新しいデータの処理ができなくなります」とは、すなわちUAでのデータ計測ができなくなるということです。<br />
          そして、UAは完全なサービス終了が予定されています。
        </p>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @mixin middleDevice {
    @media (min-width: ($sp + 1)) and (max-width: 799px) {
      @content;
    } 
  }

  .headsup {
    h2 {
      color: $colorRed;
      font-style: italic;
      font-size: 50px;
      line-height: 1.8;
      margin-bottom: 0;
      @include tb {
        font-size: 4.2vw;
      }
      @include sp {
        font-size: 4vw;
      }
      .border {
        position: relative;
        &::after {
          content: '';
          height: 8px;
          width: calc(100% + 20px);
          bottom: -16px;
          left: 50%;
          transform: translateX(-50%);
          background-color: $colorRed;
          position: absolute;
          @include sp {
            height: 4px;
            bottom: -8px;
            width: calc(100% + 10px);
          }
        }
      }
      .big {
        font-size: 107px;
        @include tb {
          font-size: 9vw;
        }
        @include sp {
          font-size: 12vw;
        }
      }
    }
    .what {
      padding-top: 100px;
      display: flex;
      align-items: center;
      @include middleDevice {
        flex-direction: column;
      }
      @include sp {
        flex-direction: column;
        padding-top: 50px;
      }
      .text {
        min-width: 480px;
        margin-right: 12px;
        flex: 1;
        @include middleDevice {
          margin: 0;
          min-width: 100%;
        }
        @include sp {
          min-width: 100%;
          margin: 0;
        }
        p {
          margin: 0;
          font-size: 18px;
          font-weight: bold;
          line-height: 1.6;
          @include tb {
            font-size: 1.6vw;
          }
          @include middleDevice {
            font-size: 18px;
            text-align: center;
          }
          @include sp {
            font-size: 12px;
            text-align: center;
          }
          &.text-under {
            @include middleDevice {
              text-align: left;
            }
            @include sp {
              text-align: left;
              font-size: 14px;
            }
          }
          .border {
            background: linear-gradient(transparent 60%, #FECCCC 60%)
          }
          .big {
            font-size: 30px;
            display: inline-block;
            margin-bottom: 10px;
            @include tb {
              font-size: 3vw;
            }
            @include middleDevice {
              font-size: 5.6vw;
              display: block;
              margin-bottom: 24px;
            }
            @include sp {
              font-size: 5.6vw;
              display: block;
              margin-bottom: 14px;
            }
          }
        }
        ul {
          margin: 45px 0 0;
          list-style: none;
          padding: 0;
          @include sp {
            margin: 30px 0 0;
          }
          li {
            position: relative;
            font-size: 16px;
            font-weight: bold;
            padding: 0 0 0 32px;
            margin: 0 0 20px;
            @include tb {
              font-size: 16px;
            }
            @include sp {
              font-size: 14px;
              line-height: 1.6;
              margin-bottom: 12px;
              padding-left: 28px;
            }
            &:last-of-type {
              margin: 0;
            }
            &::before {
              content: '';
              display: block;
              position: absolute;
              width: 18px;
              height: 18px;
              border: $colorBlack solid 1px;
              border-radius: 2px;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              @include sp {
                top: 3px;
                transform: none;
                width: 16px;
                height: 16px;
              }
            }
            &::after {
              content: '';
              display: block;
              position: absolute;
              top: 2px;
              left: 4px;
              width: 16px;
              height: 6px;
              border-left: 4px solid $colorRed;
              border-bottom: 4px solid $colorRed;
              transform: rotate(-45deg);
            }
          }
        }
      }
      .image {
        max-width: 450px;
        @include middleDevice {
          max-width: 100%;
          margin-top: 50px;
        }
        @include sp {
          max-width: 100%;
          margin-top: 50px;
        }
      }
    }
    .alert {
      margin-top: 65px;
      background-color: #F1F1F1;
      padding: 30px 50px;
      @include sp {
        padding: 50px 15px;
      }
      &-title {
        text-align: center;
        font-size: 24px;
        line-height: 1.6;
        font-weight: bold;
        margin: 0;
      }
      &-img {
        margin-top: 23px;
        @include sp {
          margin-top: 30px;
          overflow: hidden;
        }
        img {
          @include sp {
            max-width: 600px;
            width: 600px;
          }
        }
      }
      &-notes {
        text-align: center;
        margin: 20px 0 0;
        line-height: 1.6;
        font-weight: bold;
        @include sp {
          margin: 35px 0 0;
          text-align: left;
        }
      }
    }
  }
</style>
