import { createRouter,createWebHistory } from 'vue-router'
import HomePage from "./pages/HomePage.vue"
import ArchivedPage from "./pages/ArchivedPage.vue"
import ThanksPage from './pages/ThanksPage.vue'

const routes = [
  { path: '/ga4', component: HomePage },
  { path: '/archive', component: ArchivedPage },
  { path: '/thanks', component: ThanksPage },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router