<script setup>
  import { reactive } from 'vue'

  const list = reactive({
    title: ['最適な計測環境を提供', '強力なサポート体制', '既存の設定を無料診断'],
    body: [
      'エンジニアリングにも精通した<br />トップレベルのデータアナリストが担当。<br />お客様のニーズに合わせて設定を最適化。',
      '導入後の運用まで手厚くサポート。<br />GA4の使い方から活用方法まで<br />丁寧にレクチャーいたします。',
      'すでにGA4を導入済みであれば、<br />まずは設定内容の無料診断から。<br />ご希望に応じて改善点をご提案いたします。',
    ]
  })
</script>

<template>
  <section id="service" class="service">
    <div class="wrap">
      <h2>
        <img src="@/assets/img/service_logo.png" />
        <span>PROJECT GROUP</span>の<br class="middleDevice" />GA4導入支援サービスなら！
      </h2>
      <div class="service-contents">
        <div v-for="(title, i) in list.title" class="service-content" :key="i">
          <div class="box">
            <div class="box-inner">
              <div class="box-text">
                <h3 v-html="title" />
                <p class="body" v-html="list.body[i]" />
              </div>
              <div class="box-img">
                <img :src="require(`@/assets/img/v2/service_img0${i+1}.png`)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>

  .middleDevice {
    display: none;
    @include middleDevice {
      display: inline;
    }
    @include sp {
      display: inline;
    }
  }

  .service {
    // margin-bottom: 75px;
    // @include sp {
    //   padding-top: 50px;
    //   margin-bottom: 50px;
    // }
    .wrap {
      width: 90%;
      max-width: 1280px;
    }
    h2 {
      margin-bottom: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include middleDevice {
        display: block;
        line-height: 1.6;
      }
      @include sp {
        display: block;
        line-height: 1.6;
        margin-bottom: 50px;
      }

      img {
        width: 44px;
        margin-right: 10px;
        @include middleDevice {
          display: block;
          margin: 0 auto 10px;
        }
        @include sp {
          display: block;
          margin: 0 auto 10px;
        }
      }
      span {
        color: $colorRed;
        margin-right: 6px;
      }
    }
    &-contents {
      display: flex;
      flex-direction: row;
      gap: 12px;
      @include middleDevice {
        flex-direction: column;
        gap: 45px;
      }
      @include sp {
        flex-direction: column;
        gap: 45px;
      }
    }
    &-content {
      flex: 1;
      height: 440px;
      transition: .3s;
      @include tb {
        width: calc(100% - 15vw);
      }
      @include middleDevice {
        width: 100%;
      }
      @include sp {
        width: 100%;
      }
      &:hover {
        transform: scale(1.02, 1.02);
      }
      // &:hover h3::after {
      //   transform: scale(1, 1);
      // }


      .box {
        width: 100%;
        height: 100%;
        padding: 24px;
        background-color: #FFF;
        &-inner {
          // display: flex;
          // justify-content: center;
          // align-items: center;
          // position: relative;
          z-index: 2;
          @include middleDevice {
            flex-direction: column;
          }
          @include sp {
            flex-direction: column;
          }
        }
        &-text {
          @include middleDevice {
            padding: 36px 23px 36px 43px;
            width: 100%;
          }
          @include sp {
            padding: 18px 12px;
            width: 100%;
          }
          h3 {
            color: $colorOrange;
            margin: 0 0 20px;
            font-size: 24px;
            font-weight: bold;
            line-height: 1.4;
            position: relative;
            @include tb {
              font-size: 2.2vw;
              margin-bottom: 1.5vw;
            }
            @include middleDevice {
              font-size: 24px;
              margin: 0;
            }
            @include sp {
              font-size: 18px;
              margin: 0;
            }
            &::after {
              position: absolute;
              bottom: -6px;
              width: 100%;
              left: 0;
              content: '';
              height: 3px;
              background: $colorOrange;
              // transform: scale(0, 1);
              // transform-origin: left top;
              // transition: transform .3s;
            }
          }
          .body {
            line-height: 1.6;
            font-size: 15px;
            margin: 0;
            height: 100px;
            @include tb {
              font-size: 1.6vw;
              margin-top: 3vw;
            }
            @include middleDevice {
              font-size: 14px;
              margin-top: 22px;
            }
            @include sp {
              font-size: 14px;
              margin-top: 12px;
            }
          }
        }
        &-img {
          width: 100%;
        }
      }
    }
  }

</style>