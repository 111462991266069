<script setup>
  import { ref, reactive, watch } from 'vue'
  //import axios from 'axios'
  import { ElNotification } from 'element-plus'
  import { useRouter, useRoute } from 'vue-router'

  const router = useRouter()
  const route = useRoute()
  const form = reactive({
    name: '',
    company: '',
    email: '',
    tel: '',
    explanation: 'はい',
    note: '',
    gclid: '',
    fbclid: ''
  })
  const regEmail = ref( /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)
  const regTel = ref(/^\d*$/)
  const valid = ref(false)

  watch(() => form, (next) => {
    if (next.name && next.company && next.email && next.tel && next.explanation) {
      if (regEmail.value.test(next.email) && next.tel.length >= 10 && next.tel.length <= 11 && regTel.value.test(next.tel)) {
        valid.value = true
      } else {
        valid.value = false
      }
    } else {
      valid.value = false
    }
  }, { deep: true })

  const getOptions = (body) => {
    const options = {
        credentials: 'include',
        method: 'POST',
        mode: 'no-cors',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
      };
    return options
  };

  const getBody = () => {
    const body = {
      errors: {},
      name: form.name,
      company: form.company,
      email: form.email,
      tel: form.tel,
      explanation: form.explanation,
      note: form.note,
      gclid: route.query.gclid,
      fbclid: route.query.gclid,
      service: "GA4 問い合わせ"
    };
    return body;
  }

  const postInfo = () => {
    const url_on_sheet = "https://script.google.com/macros/s/AKfycby8ODRYHQiHPEOx46q2w_ap1DojsJeCPPSwtXi2eJuWvvQTt9fj3EZ121x2eXwANsSK2w/exec";
    const body = getBody();
    const options = getOptions(body);

    fetch(url_on_sheet, options)
      .then(response => {
        return response.text()
      })
      .then((body) => {
        console.log(body ? JSON.parse(body) : {})
        Object.keys(form).forEach(key => {
          if (key === 'explanation') {
            form[key] = 'はい'
          } else {
            form[key] = ''
          }
        })
        router.push('/contactThanks')
      })
      .catch(() => {
          ElNotification({
            title: 'エラー',
            message: 'エラーが発生しました',
            type: 'error',
          })
        })
  };

  const actionRequestContact = () => {
    if(valid.value){
      postInfo();
    }
  }

  // const actionRequestContact = () => {
  //   if (valid.value) {
  //     const fd = new FormData()
  //     const corsProxy = 'https://proxy.project-g.co.jp:8080/'
  //     const gfUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSeSy3po-AUAofsLav8OFTfvP_IU6RZE7HaESjbxBBuCHjQilg/formResponse'

  //     fd.append('entry.428813877', form.name)
  //     fd.append('entry.27125418', form.company)
  //     fd.append('entry.1743585167', form.email)
  //     fd.append('entry.443167019', form.tel)
  //     fd.append('entry.685430318', form.explanation)
  //     fd.append('entry.41251624', form.note)

  //     axios
  //       .post(`${corsProxy}${gfUrl}`, fd)
  //       .then(() => {

  //         Object.keys(form).forEach(key => {
  //           if (key === 'explanation') {
  //             form[key] = 'はい'
  //           } else {
  //             form[key] = ''
  //           }
  //         })

  //         ElNotification({
  //           title: 'お問い合わせを受け付けました。',
  //           message: 'お問い合わせありがとうございます。',
  //           type: 'success',
  //         })
  //       })
  //       .catch(() => {
  //         ElNotification({
  //           title: 'エラー',
  //           message: 'エラーが発生しました',
  //           type: 'error',
  //         })
  //       })
  //   }
  // }
</script>

<template>
  <div class="contact__form-label">
    お名前<span class="required" v-text="'必須'" />
  </div>
  <div class="contact__form-input">
    <el-input v-model="form.name" auto-complete="name" placeholder="お名前をご入力ください" />
  </div>
  <div class="contact__form-label">
    会社名<span class="required" v-text="'必須'" />
  </div>
  <div class="contact__form-input">
    <el-input v-model="form.company" placeholder="PROJECT GROUP株式会社" />
  </div>
  <div class="contact__form-label">
    メールアドレス<span class="required" v-text="'必須'" />
  </div>
  <div class="contact__form-input">
    <el-input v-model="form.email" placeholder="example@project-g.co.jp" />
  </div>
  <div class="contact__form-label">
    電話番号<span v-text="'(ハイフンなし)'" /><span class="required" v-text="'必須'" />
  </div>
  <div class="contact__form-input">
    <el-input v-model="form.tel" placeholder="0123456789" />
  </div>
  <div class="contact__form-label">
    対面でサービスの説明を希望されますか？<span class="required" v-text="'必須'" />
  </div>
  <div class="contact__form-input">
    <el-radio-group v-model="form.explanation">
      <el-radio label="はい">はい</el-radio>
      <el-radio label="いいえ">いいえ</el-radio>
    </el-radio-group>
  </div>
  <div class="contact__form-label">
    お問い合わせ内容<span v-text="'(任意/自由記述)'" />
  </div>
  <div class="contact__form-input">
    <el-input
      v-model="form.note"
      type="textarea"
      :autosize="{ minRows: 4, maxRows: 6}"
      placeholder="お問い合わせ内容をご記入ください"
    />
  </div>
  <div
    class="contact__form-btn"
    :class="{ 'disabled': !valid }"
    @click="actionRequestContact"
    v-text="'今すぐ無料で相談する'"
  />
</template>

<style lang="scss" scoped>
  .contact__form {
    &-label {
      font-weight: bold;
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      letter-spacing: 0;
      @include middleDevice {
        font-size: 12px;
      }
      @include sp {
        font-size: 12px;
      }
      span {
        font-size: 12px;
        display: inline-block;
        margin-left: 4px;
        font-weight: 300;
        @include middleDevice {
          font-size: 10px;
        }
        @include sp {
          font-size: 10px;
        }
        &.required {
          color: $colorRed;
          font-weight: bold;
        }
      }
    }
    &-input {
      margin-bottom: 18px;
    }
    &-btn {
      width: 210px;
      margin: 20px auto 0;
      background: linear-gradient(270deg, #4800A3 22.95%, #8008F9 83.18%);
      color: #FFF;
      text-align: center;
      padding: 16px 10px;
      cursor: pointer;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      &.disabled {
        background: #c1c3cb;
        color: #949494;
        cursor: not-allowed;
      }
      @include middleDevice {
        font-size: 14px;
        padding: 14px 10px;
        margin: 25px auto 0;
      }
      @include sp {
        font-size: 14px;
        padding: 14px 10px;
        margin: 25px auto 0;
      }
    }
  }
</style>
