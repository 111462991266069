<script setup>
  import { reactive, ref, watch } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { ElNotification, ElLoading } from 'element-plus'

  // import HeaderNavi from '@/components/HeaderNavi.vue'
  import FooterNavi from '@/components/FooterNavi.vue'
  import MainContent from '@/components/v2/MainContent.vue'
  
  const router = useRouter()
  const route = useRoute()
  const dialog_contact = ref(false)
  const form = reactive({
    name: '',
    company: '',
    email: '',
    tel: '',
    note: '',
    gclid: '',
    fbclid: ''
  })
  const regEmail = ref( /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)
  const regTel = ref(/^\d*$/)
  const valid = ref(false)

  const toggleDialogContact = (bool) => dialog_contact.value = bool

  watch(() => form, (next) => {
    if (next.name && next.company && next.email && next.tel) {
      const tel = next.tel.replace(/-/g, '');
      if (regEmail.value.test(next.email) && tel.length >= 10 && tel.length <= 11 && regTel.value.test(tel)) {
        valid.value = true
      } else {
        valid.value = false
      }
    } else {
      valid.value = false
    }
  }, { deep: true })

  const getOptions = (body) => {
    return {
      credentials: 'include',
      method: 'POST',
      mode: 'no-cors',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body)
    };
  };

  const getBody = () => {
    return {
      errors: {},
      name: form.name,
      company: form.company,
      email: form.email,
      tel: form.tel,
      note: form.note,
      gclid: route?.query?.gclid,
      fbclid: route?.query?.fbclid,
    };
  }

  const onSubmit = () => {
    if(valid.value) {
      const gasUrl = "https://script.google.com/macros/s/AKfycby4wVwaa_nkUEvPcBhlJYkBk1ZcmWCdY6PzJ0DI2htvZuFvOU12tEIiIT1079k1Bj-cRQ/exec";
      const body = getBody();
      const options = getOptions(body);

      const loadingInstance = ElLoading.service({ fullscreen: true, background: 'rgba(26, 26, 26, 0.4)', text: '送信中...' });

      fetch(gasUrl, options)
        .then(() => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({ event: 'submit_form' });
          dialog_contact.value = false;
          loadingInstance.close();
          router.push('/thanks');
        })
        .catch((e) => {
          console.error(e);
          loadingInstance.close();
          ElNotification({
            title: 'エラー',
            message: 'エラーが発生しました',
            type: 'error',
          });
        })
    }
  };

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }
</script>

<template>
  <!-- <HeaderNavi @toggleDialogContact="toggleDialogContact" /> -->
  <div id="container">
    <MainContent @toggleDialogContact="toggleDialogContact" />

    <h2>お問合せはこちらから</h2>
    <el-form id="footer-form">
      <div class="contact__form-label">
        お名前<span class="required" v-text="'必須'" />
      </div>
      <div class="contact__form-input">
        <el-input v-model="form.name" auto-complete="name" placeholder="アナリティクス 太郎" />
      </div>
      <div class="contact__form-label">
        会社名<span class="required" v-text="'必須'" />
      </div>
      <div class="contact__form-input">
        <el-input v-model="form.company" placeholder="PROJECT GROUP株式会社" />
      </div>
      <div class="contact__form-label">
        メールアドレス<span class="required" v-text="'必須'" />
      </div>
      <div class="contact__form-input">
        <el-input v-model="form.email" placeholder="info@project-g.co.jp" />
      </div>
      <div class="contact__form-label">
        電話番号<span class="required" v-text="'必須'" />
      </div>
      <div class="contact__form-input">
        <el-input v-model="form.tel" placeholder="0362069608" />
      </div>
      <div class="contact__form-label">
        お問合せ内容<span v-text="'(任意)'" />
      </div>
      <div class="contact__form-input">
        <el-input
          v-model="form.note"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
          placeholder="必要であればご記入ください"
        />
      </div>
      <div
        class="contact__form-btn"
        :class="{ 'disabled': !valid }"
        @click="onSubmit"
        v-text="'送信'"
      />
    </el-form>
  </div>

  <FooterNavi />

  <el-dialog v-model="dialog_contact" class="dialog">
    <el-form>
      <p class="dialog-title">お問合せはこちらから</p>
      <div class="contact__form-label">
        お名前<span class="required" v-text="'必須'" />
      </div>
      <div class="contact__form-input">
        <el-input v-model="form.name" auto-complete="name" placeholder="アナリティクス 太郎" />
      </div>
      <div class="contact__form-label">
        会社名<span class="required" v-text="'必須'" />
      </div>
      <div class="contact__form-input">
        <el-input v-model="form.company" placeholder="PROJECT GROUP株式会社" />
      </div>
      <div class="contact__form-label">
        メールアドレス<span class="required" v-text="'必須'" />
      </div>
      <div class="contact__form-input">
        <el-input v-model="form.email" placeholder="info@project-g.co.jp" />
      </div>
      <div class="contact__form-label">
        電話番号<span class="required" v-text="'必須'" />
      </div>
      <div class="contact__form-input">
        <el-input v-model="form.tel" placeholder="0362069608" />
      </div>
      <div class="contact__form-label">
        お問合せ内容<span v-text="'(任意)'" />
      </div>
      <div class="contact__form-input">
        <el-input
          v-model="form.note"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
          placeholder="必要であればご記入ください"
        />
      </div>
      <div
        class="contact__form-btn"
        :class="{ 'disabled': !valid }"
        @click="onSubmit"
        v-text="'送信'"
      />
    </el-form>
  </el-dialog>

  <div id="page-top" @click="scrollTop" />
</template>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
  @import url('https://unpkg.com/scroll-hint@latest/css/scroll-hint.css');

  * {
    box-sizing: border-box;
  }

  html {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1px;
    font-feature-settings: "palt";
    color: $colorBlack;
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  h2 {
    text-align: center;
    margin: 0 0 36px;
    font-size: 30px;
    font-weight: bold;
    @include tb {
      font-size: 3.8vw;
    }
    @include sp {
      font-size: 6vw;
      margin: 0 0 30px;
    }
  }

  section {
    padding: 80px 0;
    @include sp {
      padding: 40px 0;
    }
  }

  // #container {
  //   margin-top: 60px;
  // }

  .wrap {
    width: 100%;
    max-width: 1060px;
    margin: auto;
    padding: 0 30px;
    @include sp {
      padding: 0 15px;
    }
  }

  .red {
    color: $colorRed;
  }

  .gradient {
    background: linear-gradient(270deg, #EB1002 22.95%, #F95008 83.18%);
  }

  .sp {
    display: none;
    @include sp {
      display: inline;
    }
  }

  .dialog {
    width: 44vw !important;
    min-width: 310px;
    max-width: 600px;
    .el-dialog__headerbtn {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: $colorBlack;
      top: -15px;
      right: -15px;
      .el-dialog__close {
        color: #FFF;
      }
    }
    .el-dialog__body {
      padding-top: 0;
      .dialog-title {
        color: $colorBlack;
        font-size: 18px;
        text-align: center;
        font-weight: bold;
        margin: 20px 0;
        @include sp {
          line-height: 1.6;
        }
      }
    }
    &-flex {
      display: flex;
      @include sp {
        flex-direction: column;
      }
      .el-input__wrapper {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  form {
    width: 40vw;
    min-width: 280px;
    max-width: 540px;
    margin: 32px auto;
  }

  .contact__form {
    &-label {
      font-weight: bold;
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      letter-spacing: 0;
      @include middleDevice {
        font-size: 12px;
      }
      @include sp {
        font-size: 12px;
      }
      span {
        font-size: 12px;
        display: inline-block;
        margin-left: 4px;
        font-weight: 300;
        @include middleDevice {
          font-size: 10px;
        }
        @include sp {
          font-size: 10px;
        }
        &.required {
          color: $colorRed;
          font-weight: bold;
        }
      }
    }
    &-input {
      margin-bottom: 18px;
    }
    &-btn {
      width: 32%;
      margin: 20px auto 0;
      background: linear-gradient(270deg, #4800A3 22.95%, #8008F9 83.18%);
      color: #FFF;
      text-align: center;
      padding: 16px 10px;
      cursor: pointer;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      border: #FFF solid 2px;
      transition: .3s;
      &.disabled {
        background: #c1c3cb;
        color: #949494;
        cursor: not-allowed;
      }
      &:not(.disabled):hover {
        transform: scale(1.03, 1.03);
        background: linear-gradient(0deg, #4800A3 22.95%, #8008F9 83.18%);
        border-radius: 12px;
      }
      @include middleDevice {
        font-size: 14px;
        padding: 14px 10px;
        margin: 25px auto 0;
      }
      @include sp {
        font-size: 14px;
        padding: 14px 10px;
        margin: 25px auto 0;
      }
    }
  }

  #page-top {
    display: none;
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
    border: #FFF solid 1px;
    border-radius: 50%;
    background-color: rgba(26, 26, 26, 0.25);
    @include sp {
      display: block;
    }
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 10px;
      height: 10px;
      border-top: 4px solid #FFF;
      border-left: 4px solid #FFF;
      transform: rotate(45deg) translate(-50%);
      left: calc(50% - 1px);
      top: calc(50% - 1px);
    }
  }
</style>
