<script setup>
  import { reactive } from 'vue'

  const list = reactive({
    name: [
      '月間500万PVメディア',
      'ビジネスマッチングプラットフォーム',
      '人気アパレルECサイト'
    ],
    title: [
      'クリック解析でサイト構成を改善し、CVR向上！',
      '「会員／非会員」ごとの分析で、ユーザー特性による行動の差異を発見！',
      '正確なeコマース設定で、購入状況を細かく分析可能に！',
    ],
    body: [
      '自社でサイト改善施策を行っていましたが、GA4への移行に関してはリソースが不足しており委託させていただきました。特にクリック解析は複雑な設定が必要なため手が出せておらず、この機会にサイト内のあらゆるリンククリックを計測できるよう設定をお願いしました。結果的に、CVに寄与している導線と逆に悪影響を与えているものが判明し、データドリブンでサイトの構成を見直すことで、CVRの向上に繋がりました。',
      'はじめの無料相談の際に、弊社の分析ニーズと照らし合わせて既存のGA設定を診断してくださり、不足している点を列挙していただきました。中でも「ユーザーを会員と非会員で分けて分析するべきでは？」というご提案はクリティカルで、実装によって最も効果が出ました。会員化するユーザーの行動に特徴があることを発見し、それをもとに施策を実施することで会員登録率を上昇させることができました。',
      'GA4に移行したらeコマース設定がおかしくなってしまい、全く活用できずにいました。設定の修正に加え、商品カテゴリや展開ブランドなど様々な軸で数値を見れるようになり、各担当者からも好評です。また、商品ページの閲覧からカート追加・購入に至るまでのCVフローを、レポートで分かりやすく可視化していただき、サイト内の課題を発見しやすくなりました。',
    ],
  })
</script>

<template>
  <section id="voice" class="voice">
    <div class="wrap">
      <h2>導入事例</h2>
      <div class="voice-contents">
        <div v-for="(title, i) in list.title" class="voice-content" :key="i">
          <h3 v-text="title" />
          <div class="balloon-set-box left">
            <div class="voice-content-client icon-box">
              <img class="icon" :src="require(`@/assets/img/v2/voice_img0${i+1}.png`)" />
            </div>
            <div class="voice-content-voice balloon">
              <p v-text="list.body[i]" />
            </div>
          </div>
          <span v-text="'ー ' + list.name[i] + '様'" />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .voice {
    background-color: #FBE7D8;
    @include sp {
      padding-bottom: 50px;
      padding-top: 100px;
    }
    h2 {
      margin-bottom: 80px;
      @include sp {
        margin-bottom: 50px;
        line-height: 1.6; 
      }
    }
    &-content {
      width: 100%;
      margin-top: 72px;
      transition: 0.3s;
      h3 {
        text-align: center;
        font-size: 24px;
        color: $colorOrange;
      }
      h4 {
        text-align: center;
        // font-weight: 300;
      }
      p {
        font-size: 16px;
        line-height: 1.8;
      }
      span {
        font-size: 13px;
        float: right;
        margin-right: 22px;
      }
      &:hover {
        transform: scale(1.02, 1.02);
      }
    }

    .balloon-set-box {
      display: flex;
      flex-wrap: wrap;
    }
    .balloon-set-box.left { /* 左 */
      flex-direction: row;
    }
    .balloon-set-box.right { /* 右 */
      flex-direction: row-reverse; /* アイコンと吹き出しの並びを入れ替える */
    }
    .balloon {
      position: relative; /* 三角の位置を固定するために設定 */
      display: inline-block;
      max-width: 84%;
      // max-width: 300px;
      margin: 10px; /* 上 左右 下のマージン */
      padding: 4px 28px; /* ふきだし内の余白 */
      background: #fff; /* 背景色 */
      text-align: left; /* テキストの揃え */
      border-radius: 15px;
    }
    .balloon::after {
      content: '';
      border: 14px solid transparent;
      border-top-color: #fff;
      position: absolute;
      top: 0;
    }
    .left .balloon::after { /* 左側からの三角の位置 */
      left: -10px;
    }
    .right .balloon::after { /* 右側からの三角の位置 */
      right: -10px;
    }

    .icon-box {
      width: 13%;
      padding: 8px;
      // height: 120px;
      // border-radius: 50%;
      // border: 2px solid #fff;
      // box-shadow: 0 0 6px rgba(0,0,0,0.3);
      // overflow: hidden;
    }
  }

</style>
