<script setup>
</script>

<template>
  <section id="headsup" class="headsup">
    <div class="wrap">
      <h2>なぜ今GA4に<br class="sp" />移行するべきなのか？</h2>
      <div class="what">
        <div class="boxes">
          <div class="box">
            <p class="box-title">
              過去期間と対比させた<br />解析ができなくなる
            </p>
            <p class="box-text">
              しばらくデータを溜めておく必要があるので、<br />
              UAがサービス終了する2023年7月1日に<br />
              間に合わせるだけでは遅いです。
            </p>
          </div>
          <div class="box">
            <p class="box-title">
              大きく変更された解析手法に<br />
              慣れておく必要がある
            </p>
            <p class="box-text">
              GA4での解析には、従来のUAとは異なる点がいくつもあります。<br />
              一朝一夕でマスターするのは困難なので、<br class="sp" />
              UAと併用しながら理解を深めていくことをお勧めいたします。
            </p>
          </div>
        </div>
      </div>
      <div class="alert">
        <p class="alert-title">Googleアナリティクスの管理画面上に<br />以下のような<span class="red">アラートが<br class="sp" />出ていたら要注意</span>です！</p>
        <div class="alert-img">
          <img src="@/assets/img/headsup_analytics.jpg" />
        </div>
        <p class="alert-notes">
          このアラートが出ている場合、<br />
          あなたがお使いのGoogleアナリティクスは旧バージョン（ユニバーサルアナリティクス＝UA）です。<br />
          新バージョン（GA4）への移行が必要です。
        </p>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @mixin middleDevice {
    @media (min-width: ($sp + 1)) and (max-width: 799px) {
      @content;
    } 
  }

  @keyframes flash {
    0%,50% {
      color: $colorRed;
    }
    51%,100% {
      color: #252525;
    }
  }

  .headsup {
    padding-bottom: 100px;
    @include sp {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    h2 {
      margin-bottom: 100px;
      @include sp {
        line-height: 1.6;
        margin-bottom: 50px;
      }
    }
    .what {
      .boxes {
        display: flex;
        align-items: center;
        @include sp {
          flex-direction: column;
        }
        .box {
          width: 50%;
          margin: 0 34px 0 0;
          position: relative;
          z-index: 2;
          transition: .3s;
          &:hover {
            transform: scale(1.02, 1.02);
          }
          @include tb {
            margin: 0 2vw 0 0;
          }
          @include sp {
            width: 100%;
            margin: 0;
          }
          &::after {
            content: "01";
            position: absolute;
            right: 0;
            top: 0;
            font-size: 120px;
            z-index: -1;
            // font-family: "M PLUS 1p";
            color: #F9CA94;
            font-weight: bold;
            @include tb {
              font-size: 16vw;
            }
            @include sp {
              right: -15px;
            }
          }
          &:last-of-type {
            margin: 0 0 0 34px;
            @include tb {
              margin: 0 0 0 2vw;
            }
            @include sp {
              margin: 47px 0 0 0;
            }
            &::after {
              content: "02";
            }
          }
          &-title {
            font-size: 24px;
            font-weight: bold;
            line-height: 1.6;
            margin: 0;
            @include tb {
              font-size: 2.4vw;
            }
            @include sp {
              font-size: 18px;
            }
          }
          &-text {
            line-height: 1.6;
            margin: 12px 0 0;
            padding: 2px 0 4px 14px;
            border-left: #F9CA94 solid 5px;
            @include tb {
              font-size: 2vw;
            }
          }
        }
      }
    }
    .alert {
      margin-top: 65px;
      background-color: #F1F1F1;
      padding: 30px 50px;
      @include sp {
        padding: 50px 15px;
        width: calc(100% + 30px);
        margin-left: -15px;
      }
      &-title {
        text-align: center;
        font-size: 24px;
        line-height: 1.6;
        font-weight: bold;
        margin: 0;
        span {
          font-size: 27px;
          animation: flash 1.3s linear infinite;
        }
      }
      &-img {
        margin-top: 23px;
        @include sp {
          margin-top: 30px;
          overflow: hidden;
        }
        img {
          @include sp {
            max-width: 600px;
            width: 600px;
          }
        }
      }
      &-notes {
        text-align: center;
        margin: 20px 0 0;
        font-size: 15px;
        line-height: 1.6;
        font-weight: bold;
        @include sp {
          margin: 35px 0 0;
          text-align: left;
        }
      }
    }
  }
</style>
