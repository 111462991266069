<script setup>
  import { reactive, ref, watch } from 'vue'
  //import axios from 'axios'
  import { ElNotification } from 'element-plus'

  import HeaderNavi from '@/components/HeaderNavi.vue'
  import MainContent from '@/components/MainContent.vue'
  import FooterNavi from '@/components/FooterNavi.vue'
  import { useRouter } from 'vue-router'
  
  const router = useRouter()
  const show = ref(false)
  const form = reactive({
    name: '',
    company: '',
    email: '',
    tel: ''
  })
  const regEmail = ref( /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)
  const regTel = ref(/^\d*$/)
  const valid = ref(false)

  const dialogShow = (bool) => show.value = bool

  watch(() => form, (next) => {
    if (next.name && next.company && next.email && next.tel) {
      if (regEmail.value.test(next.email) && next.tel.length >= 10 && next.tel.length <= 11 && regTel.value.test(next.tel)) {
        valid.value = true
      } else {
        valid.value = false
      }
    } else {
      valid.value = false
    }
  }, { deep: true })

  const getOptions = (body) => {
    const options = {
        credentials: 'include',
        method: 'POST',
        mode: 'no-cors',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
      };
    return options
  };

  const getBody = () => {
    const body = {
      errors: {},
      name: form.name,
      company: form.company,
      email: form.email,
      tel: form.tel,
      explanation: "",
      note: "",
      service: "GA4 資料請求"
    };
    return body;
  }

  const updateSheet = () => {
    const url_on_sheet = "https://script.google.com/macros/s/AKfycby8ODRYHQiHPEOx46q2w_ap1DojsJeCPPSwtXi2eJuWvvQTt9fj3EZ121x2eXwANsSK2w/exec";
    const body = getBody();
    const options = getOptions(body);

    fetch(url_on_sheet, options)
      .then(response => {
        return response.text()
      })
      .then((body) => {
        console.log(body ? JSON.parse(body) : {})
        Object.keys(form).forEach(key => {
          form[key] = ''
        })
        show.value = false 
      })
      .catch(() => {
          ElNotification({
            title: 'エラー',
            message: 'エラーが発生しました',
            type: 'error',
          })
    })
  };

  const sendMail = () => {
    const body = getBody()
    const options = getOptions(body)
    const url_on_gas = "https://script.google.com/macros/s/AKfycbwzuzXBRxzeEXIhXIRuZofsGv7GFUDgQGAOqrXVHw4JaAnqkdjsFgprgVGvY9itUmHg/exec";

    fetch(url_on_gas, options)
      .then(response => {
        return response.text()
      })
      .then((body) => {
        router.push('/documentThanks')
        console.log(body ? JSON.parse(body) : {})
      })
      .catch(err => console.error('error:' + err));
  };

  const actionRequestDocument = () => {
    if(valid.value){
      updateSheet();
      sendMail();
    }
  };
  // const actionRequestDocument = () => {
  //   if (emailCheck.value) {
  //     const fd = new FormData()
  //     const corsProxy = 'https://proxy.project-g.co.jp:8080/'
  //     const gfUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSd9XA8-hFk9kA9yhezXkn3GduMrfkEsY8t1gT4ZeCfGo53k1A/formResponse'

  //     fd.append('entry.303815129', email.value)

  //     axios
  //       .post(`${corsProxy}${gfUrl}`, fd)
  //       .then(() => {
  //         email.value = ''
  //         show.value = false
  //         ElNotification({
  //           title: '資料請求を受け付けました。',
  //           message: '2,3営業日以内に記入いただいたメールアドレスに資料をお送りいたします。',
  //           type: 'success',
  //         })
  //       })
  //       .catch(() => {
  //         ElNotification({
  //           title: 'エラー',
  //           message: 'エラーが発生しました',
  //           type: 'error',
  //         })
  //       })
  //   }
  // }
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }
</script>

<template>
  <div id="ga4-lp">
    <HeaderNavi @dialog="dialogShow" />
    <MainContent @dialog="dialogShow" />
    <FooterNavi />

    <el-dialog v-model="show" custom-class="dialog">
      <p class="dialog-title">Googleアナリティクス4<br class="sp" />導入支援サービス 資料請求</p>
      <div class="dialog-form">
        <div class="dialog-form--label">
          お名前<span class="required" v-text="'必須'" />
        </div>
        <div class="dialog-form--input">
          <el-input v-model="form.name" auto-complete="name" placeholder="お名前をご入力ください" />
        </div>
        <div class="dialog-form--label">
          会社名<span class="required" v-text="'必須'" />
        </div>
        <div class="dialog-form--input">
          <el-input v-model="form.company" placeholder="PROJECT GROUP株式会社" />
        </div>
        <div class="dialog-form--label">
          メールアドレス<span class="required" v-text="'必須'" />
        </div>
        <div class="dialog-form--input">
          <el-input v-model="form.email" placeholder="example@project-g.co.jp" />
        </div>
        <div class="dialog-form--label">
          電話番号<span v-text="'(ハイフンなし)'" /><span class="required" v-text="'必須'" />
        </div>
        <div class="dialog-form--input">
          <el-input v-model="form.tel" placeholder="0123456789" />
        </div>
        <div class="dialog-button gradient" :class="{'disabled': !valid}" @click="actionRequestDocument">送信</div>
      </div>
    </el-dialog>

    <div id="page-top" @click="scrollTop" />
  </div>
</template>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Sawarabi+Mincho:wght@400;700;&display=swap');
  @import url('https://unpkg.com/scroll-hint@latest/css/scroll-hint.css');

  * {
    box-sizing: border-box;
  }

  html {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1px;
    font-feature-settings: "palt";
    color: $colorBlack;
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  h2 {
    text-align: center;
    margin: 0 0 36px;
    font-size: 30px;
    font-weight: bold;
    @include tb {
      font-size: 3.8vw;
    }
    @include sp {
      font-size: 6vw;
      margin: 0 0 30px;
    }
  }

  section {
    padding: 80px 0;
    @include sp {
      padding: 40px 0;
    }
  }

  .wrap {
    width: 100%;
    max-width: 1100px;
    margin: auto;
    padding: 0 30px;
    @include sp {
      padding: 0 15px;
    }
  }

  .red {
    color: $colorRed;
  }

  .other-font {
    font-family: "Sawarabi Mincho";
  }

  .gradient {
    background: linear-gradient(270deg, #EB1002 22.95%, #F95008 83.18%);
  }

  .sp {
    display: none;
    @include sp {
      display: inline;
    }
  }

  // element ui
  #ga4-lp {
    .el-input {
      &__wrapper {
        background-color: #FFF;
        border-color: #CCC;
      }
      &__inner {
        height: 45px;
        line-height: 45px;
        font-family: 'Noto Sans JP', sans-serif;
        font-size: 13px;
        color: $colorBlack;
        &::placeholder {
          font-size: 13px;
          font-family: 'Noto Sans JP', sans-serif;
        }
      }
    }
    .el-radio-group {
      .el-radio {
        &__input {
          .el-radio__inner {
            border-color: #CCC;
          }
          &.is-checked {
            .el-radio__inner {
              background: #FFF;
              &::after {
                background-color: $colorPurple;
                width: 8px;
                height: 8px;
              }
            }
          }
        }
        &__label {
          color: $colorBlack !important;
          padding-left: 4px;
          font-size: 13px;
          font-weight: 300;
        }
      }
    }
    .el-textarea {
      &__inner {
        background-color: #FFF;
        border-color: #CCC;
        font-family: 'Noto Sans JP', sans-serif;
        font-size: 13px;
        color: $colorBlack;
        &::placeholder {
          font-size: 13px;
          font-family: 'Noto Sans JP', sans-serif;
        }
      }
    }
    .dialog {
      width: 500px;
      .el-dialog__headerbtn {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: $colorBlack;
        top: -15px;
        right: -15px;
        .el-dialog__close {
          color: #FFF;
        }
      }
      .el-dialog__body {
        padding-top: 0;
        .dialog-title {
          color: $colorBlack;
          font-size: 18px;
          text-align: center;
          font-weight: bold;
          margin: 20px 0;
          @include sp {
            line-height: 1.6;
          }
        }
      }
      &-form {
        &--label {
          font-weight: bold;
          margin-bottom: 6px;
          display: flex;
          align-items: center;
          letter-spacing: 0;
          @include middleDevice {
            font-size: 12px;
          }
          @include sp {
            font-size: 12px;
          }
          span {
            font-size: 12px;
            display: inline-block;
            margin-left: 4px;
            font-weight: 300;
            @include middleDevice {
              font-size: 10px;
            }
            @include sp {
              font-size: 10px;
            }
            &.required {
              color: $colorRed;
              font-weight: bold;
            }
          }
        }
        &--input {
          margin-bottom: 18px;
        }
      }
      &-flex {
        display: flex;
        @include sp {
          flex-direction: column;
        }
        .el-input__wrapper {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      &-button {
        width: 210px;
        margin: 20px auto 0;
        background: linear-gradient(270deg, #4800A3 22.95%, #8008F9 83.18%);
        color: #FFF;
        text-align: center;
        padding: 16px 10px;
        cursor: pointer;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        &.disabled {
          background: #c1c3cb;
          color: #949494;
          cursor: not-allowed;
        }
        @include middleDevice {
          font-size: 14px;
          padding: 14px 10px;
          margin: 25px auto 0;
        }
        @include sp {
          font-size: 14px;
          padding: 14px 10px;
          margin: 25px auto 0;
        }
      }
    }
  }

  #page-top {
    display: none;
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
    border: #FFF solid 1px;
    border-radius: 50%;
    background-color: rgba(26, 26, 26, 0.25);
    @include sp {
      display: block;
    }
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 10px;
      height: 10px;
      border-top: 4px solid #FFF;
      border-left: 4px solid #FFF;
      transform: rotate(45deg) translate(-50%);
      left: calc(50% - 1px);
      top: calc(50% - 1px);
    }
  }
</style>
