<template>
  <footer id="footer" class="footer">
    <div class="footer-nav">
      <div class="footer-nav__logo">
        <img src="@/assets/img/pg_logo.png" alt="PROJECT GROUP株式会社" />
      </div>
      <div class="footer-nav__links">
        <a class="footer-nav__link" href="https://project-g.co.jp/about/" target="_blank">会社概要</a>
        <a class="footer-nav__link" href="https://project-g.co.jp/policy/" target="_blank">プライバシーポリシー</a>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
  .footer {
    width: 100%;
    background-color: $colorBlack;
    padding: 20px 0;
    &-nav {
      display: flex;
      align-items: center;
      padding: 20px 34px;
      max-width: 1920px;
      margin: auto;
      @include sp {
        flex-direction: column;
        padding: 0;
      }
      &__logo {
        img {
          height: 40px;
          width: auto;
        }
      }
      &__links {
        margin-left: auto;
        display: flex;
        align-items: center;
        @include sp {
          margin: 40px 0 0;
        }
      }
      &__link {
        color: #FFF;
        &:first-of-type {
          margin-right: 20px;
        }
      }
    }
  }
</style>