<script setup>
  import { reactive } from 'vue'

  const list = reactive({
    title: ['納品までどのくらいの時間がかかりますか？', 'GA4を導入した後、自分たちで活用できるか不安です', '自分たちでGA4の設定をしてみたが、数値が正しいか不安です'],
    body: [
      '貴社のWebサイトの規模や、取得するデータの量によって変動しますが、作業開始から最短で「翌日」の納品が可能です。',
      '導入を進めさせていただくコンサルタントは、日常業務でGA4を用いWebサイトの分析をしています。<br />導入に際しては、貴社の運用状況をヒアリングさせていただき、運用開始以降も支援させていただくことが可能です。',
      '既存の設定内容を確認させていただき、再設定が必要であれば弊社が責任を持って対応させていただきます。まずはお気軽にご相談ください。'
    ]
  })
</script>

<template>
  <section id="question" class="question">
    <div class="wrap">
      <h2 v-text="'よくある質問'" />
      <dl class="question-list">
        <template v-for="(title, i) in list.title" :key="i">
          <dt v-text="title" />
          <dd v-html="list.body[i]" />
        </template>
      </dl>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .question {
    padding-bottom: 100px;
    background-color: #F3E2DA;
    @include sp {
      padding-bottom: 50px;
    }
    &-list {
      dt {
        margin-top: 60px;
        font-size: 24px;
        color: $colorOrange;
        font-weight: bold;
        position: relative;
        line-height: 1.6;
        padding: 0 0 0 24px;
        @include sp {
          margin-top: 50px;
          font-size: 18px;
        }
        &::before {
          content: '';
          height: 100%;
          width: 8px;
          position: absolute;
          left: 0;
          top: 0;
          background-color: $colorOrange;
        }
      }
      dd {
        padding: 0 0 0 24px;
        font-size: 16px;
        line-height: 1.6;
        margin: 15px 0 0;
        @include sp {
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }
  }

</style>