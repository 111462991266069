<script setup>
  import { ref } from 'vue'

  const list = ref([
    'Googleアナリティクス4に移行したいが<br /><strong>最適な設定・設計方法が分からない</strong>',
    'Googleアナリティクス4を導入・活用してみたいが<br /><strong>社内で習得するリソースがない</strong>',
    '自分たちで移行作業を行ってみたものの<br /><strong>適切に計測できているか自信がない</strong>'
  ])
</script>

<template>
  <section id="trouble" class="trouble">
    <div class="wrap">
      <h2>こんなお悩み、<br class="sp" />ありませんか？</h2>
      <div class="trouble-contents">
        <div v-for="(item, i) in list" class="trouble-content" :key="i">
            <!-- <div class="box-img">
              <img :src="require(`@/assets/img/v2/trouble_img0${i+1}.jpg`)" />
            </div> -->
          <p v-html="item" />
          <img :src="require(`@/assets/img/v2/trouble_img0${i+1}.png`)" />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>

  .middleDevice {
    display: none;
    @include middleDevice {
      display: inline;
    }
    @include sp {
      display: inline;
    }
  }

  .trouble {
    // padding-bottom: 100px;
    background-image: url('./../../../assets/img/v2/limit_bg.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-color  : #A9A9A9;
    background-blend-mode: screen;
    @include sp {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    h2 {
      margin-bottom: 60px;
    }
    // h2 {
    //   margin-bottom: 100px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   @include middleDevice {
    //     display: block;
    //     line-height: 1.6;
    //   }
    //   @include sp {
    //     display: block;
    //     line-height: 1.6;
    //     margin-bottom: 50px;
    //   }

    //   img {
    //     width: 44px;
    //     margin-right: 10px;
    //     @include middleDevice {
    //       display: block;
    //       margin: 0 auto 10px;
    //     }
    //     @include sp {
    //       display: block;
    //       margin: 0 auto 10px;
    //     }
    //   }
    //   strong {
    //     color: $colorRed;
    //     margin-right: 6px;
    //   }
    // }
    &-contents {
      display: flex;
      flex-direction: column;
      gap: 36px;
      margin: 0 auto;
      max-width: 800px;
      @include middleDevice {
        flex-direction: column;
        gap: 45px;
      }
      @include sp {
        flex-direction: column;
        gap: 45px;
      }
    }
    &-content {
      position: relative;
      display: flex;
      align-items: center;
      width: calc(100% - 240px);
      height: 140px;
      border-radius: 60px;
      box-shadow: 0 6px 8px 2px rgba(0, 0, 0, .2);
      transition: .3s;
      @include tb {
        width: calc(100% - 15vw);
      }
      @include middleDevice {
        width: 100%;
      }
      @include sp {
        width: 100%;
      }
      &:hover {
        transform: scale(1.02, 1.02);
      }
      // &:before {
      //   content: "";
      //   position: absolute;
      //   left: -74px;
      //   width: 24px;
      //   height: 24px;
      //   bottom: 0;
      //   border-radius: 50%;
      // }
      // &:after {
      //   content: "";
      //   position: absolute;
      //   left: -42px;
      //   width: 36px;
      //   height: 36px;
      //   bottom: 6px;
      //   border-radius: 50%;
      // }
      // &, &:before, &:after {
      //   box-shadow: 0 6px 8px 2px rgba(0, 0, 0, .2);
      // }

      p {
        flex: 1;
        // width: 100%;
        // height: 100%;
        // display: grid;
        // place-items: center center;
        font-size: 20px;
        line-height: 1.7;
        text-align: center;
        color: #FFF;
        // strong {
        //   font-weight: bold;
        //   font-size: 20px;
        //   color: #000 !important;
        // }
      }

      img {
        position: absolute;
      }

      &:nth-of-type(1) {
        background-color: #80A8F6;
        // &, &:before, &:after {
        //   background-color: #80A8F6;
        // }
        img {
          height: 120px;
          top: -48px;
          left: -54px;
        }
      }
      &:nth-of-type(2) {
        // margin-top: -56px;
        margin-left: auto;
        margin-right: 12px;
        z-index: 2;
        background-color: #7C7FF4;
        img {
          height: 146px;
          top: -64px;
          right: -28px;
        }
        // &, &:before, &:after {
        //   background-color: #7C7FF4;
        // }
        // &:before {
        //   left: auto;
        //   right: -74px;
        // }
        // &:after {
        //   left: auto;
        //   right: -42px;
        // }
      }
      &:nth-of-type(3) {
        // margin-top: -56px;
        margin-left: 32px;
        z-index: 3;
        background-color: #0781D6;
        img {
          height: 150px;
          top: -68px;
          left: -30px;
        }
        // &, &:before, &:after {
        //   background-color: #0781D6;
        // }
      }
      // .box {
      //   width: 100%;
      //   height: 100%;
      //   background-color: #FFF;
      //   border-radius: 20px;
        // &-inner {
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   position: relative;
        //   z-index: 2;
        //   @include middleDevice {
        //     flex-direction: column;
        //   }
        //   @include sp {
        //     flex-direction: column;
        //   }
        //   // &::after {
        //   //   content: "01";
        //   //   position: absolute;
        //   //   right: 10px;
        //   //   top: 50%;
        //   //   font-size: 120px;
        //   //   z-index: -1;
        //   //   // font-family: "M PLUS 1p";
        //   //   color: #FBE7D8;
        //   //   font-weight: bold;
        //   //   transform: translateY(-50%);
        //   //   @include middleDevice {
        //   //     top: unset;
        //   //     bottom: 8px;
        //   //     transform: unset;
        //   //   }
        //   //   @include sp {
        //   //     top: unset;
        //   //     bottom: 8px;
        //   //     transform: unset;
        //   //   }
        //   // }
        // }
        // &-img {
        //   width: 370px;
        //   @include tb {
        //     width: 36vw;
        //   }
        //   @include middleDevice {
        //     width: 100%;
        //   }
        //   @include sp {
        //     width: 100%;
        //   }
        //   img {
        //     width: 100%;
        //   }
        // }
        // &-text {
        //   // flex-grow: 1;
        //   // padding: 0 0 0 42px;
        //   @include middleDevice {
        //     padding: 36px 23px 36px 43px;
        //     width: 100%;
        //   }
        //   @include sp {
        //     padding: 18px 12px;
        //     width: 100%;
        //   }
        //   // h3 {
        //   //   margin: 0 0 20px;
        //   //   font-size: 24px;
        //   //   font-weight: bold;
        //   //   line-height: 1;
        //   //   @include tb {
        //   //     font-size: 2.2vw;
        //   //     margin-bottom: 1.5vw;
        //   //   }
        //   //   @include middleDevice {
        //   //     font-size: 24px;
        //   //     margin: 0;
        //   //   }
        //   //   @include sp {
        //   //     font-size: 18px;
        //   //     margin: 0;
        //   //   }
        //   // }
        //   font-size: 18px;
        //   line-height: 1.7;
        //   text-align: center;
        //   // margin: 0;
        //   // padding: 1px 0 2px 14px;
        //   // border-left: 5px solid #FBE7D8;
        //   @include tb {
        //     font-size: 1.6vw;
        //     margin-top: 3vw;
        //   }
        //   @include middleDevice {
        //     font-size: 14px;
        //     margin-top: 22px;
        //   }
        //   @include sp {
        //     font-size: 14px;
        //     margin-top: 12px;
        //   }
        // }
      }
      // &:nth-of-type(2) {
      //   margin-left: auto;
      //   // .box-inner {
      //   //   &::after {
      //   //     content: "02";
      //   //   }
      //   // }
      // }
      // &:nth-of-type(3) {
      //   .box-inner {
      //     &::after {
      //       content: "03";
      //     }
      //   }
      // }
    // }
  }

</style>

<!-- <script setup>
  import { ref } from 'vue'

  const list = ref([
    'Googleアナリティクス4に<br />移行したいが最適な<br />設定・設計方法が分からない',
    'Googleアナリティクス4を<br />導入・活用してみたいが社内で<br />習得するリソースがない',
    '自分たちで移行作業を<br />行ってみたものの適切に<br />設定されているかが分からない'
  ])
</script>

<template>
  <section id="trouble" class="trouble">
    <div class="wrap">
      <h2>こんなお悩み、<br class="sp" />ありませんか？</h2>
      <div class="trouble-contents">
        <div v-for="(l, i) in list" class="trouble-content" :key="i">
          <div class="trouble-content__img"><img :src="require(`@/assets/img/v2/but_img0${i+1}.jpg`)" /></div>
          <p class="trouble-content__text" v-html="l" />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @mixin middleDevice {
    @media (min-width: ($sp + 1)) and (max-width: 799px) {
      @content;
    } 
  }

  .trouble {
    padding-bottom: 100px;
    background-image: url('./../../../assets/img/v2/limit_bg.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-color  : #A9A9A9;
    background-blend-mode: screen;
    position: relative;
    @include sp {
      padding-bottom: 50px;
    }
    h2 {
      margin-bottom: 50px;
      @include sp {
        line-height: 1.6;
      }
    }
    &-contents {
      display: flex;
      justify-content: center;
      gap: 35px;
      @include middleDevice {
        flex-direction: column;
        gap: 20px 0;
      }
      @include sp {
        flex-direction: column;
        gap: 20px 0;
      }
    }
    &-content {
      width: calc(100% / 3);
      background-color: #FFF;
      padding: 0px 0px 140px;
      display: flex;
      align-items: center;
      position: relative;
      transition: 0.3s;
      @include middleDevice {
        width: 100%;
      }
      @include sp {
        width: 100%;
      }
      &__img {
        flex: 1;
        img {
          width: 100%;
        }
      }
      &__text {
        position: absolute;
        bottom: 40px;
        left: 30px;
        width: calc(100% - 60px);
        margin: 0;
        text-align: center;
        line-height: 1.6;
        font-size: 16px;
        font-weight: bold;
        @include tb {
          left: 0;
          width: 100%;
          font-size: 15px;
        }
      }
      &:hover {
        transform: scale(1.02, 1.02);
      }
    }
    // &::after {
    //   content: '';
    //   position: absolute;
    //   bottom: -40px;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   display: inline-block;
    //   border-style: solid;
    //   border-width: 40px 50px 0 50px;
    //   border-color: #B5B5B5 transparent transparent transparent;
    // }
  }

</style> -->
