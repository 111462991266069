<script setup>
  import { ref, computed } from 'vue'

  const company = ref(['asahi', 'bigcamera', 'kurand', 'kyodo', 'Mil', 'mirai', 'nexyz', 'orbis', 'pola', 'shinagawa', 'vector', 'weblio', 'xl'])

  const getStyle = computed(() => (name) => {
    switch (name) {
      case 'kyodo': return 'width: 120px'
      case 'Mil': return 'width: 100px'
      case 'xl': return 'width: 120px'
      default: return ''
    }
  })
</script>

<template>
  <section id="implementing" class="implementing">
    <h2>数多くの企業様が導入しています</h2>
    <div class="implementing__slider">
      <ul class="implementing__slider-wrap">
        <li v-for="c in company" :key="c">
          <img :src="require(`@/assets/img/company_${c}.svg`)" alt="" :style="getStyle(c)">
        </li>
      </ul>
      <ul class="implementing__slider-wrap">
        <li v-for="c in company" :key="c">
          <img :src="require(`@/assets/img/company_${c}.svg`)" alt="" :style="getStyle(c)">
        </li>
      </ul>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .implementing {
    &__slider {
      display: flex;
      align-items: center;
      height: 120px;
      overflow: hidden;
      &-wrap {
        gap: 40px;
        display: flex;
        align-items: center;
        list-style: none;
        margin-left: 20px;
        animation: loop-slide 20s infinite linear 1s both;
        @include sp {
          gap: 20px;
        }
        li {
          width: 150px;
          margin: 0 10px;
          text-align: center;
        }
      }
    }
  }

  @keyframes loop-slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
</style>
