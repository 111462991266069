<script setup>
  import { defineEmits } from 'vue'
  const emit = defineEmits(['toggleDialogContact'])

  const scroll = (id) => {
    const header = document.getElementById('header')
    const headerRect = header.getBoundingClientRect()
    const target = document.getElementById(id)
    const clientRect = target.getBoundingClientRect()
    const py = window.pageYOffset + clientRect.top - headerRect.height
    window.scrollTo({
      top: py,
      behavior: "smooth"
    })
  }
</script>

<template>
  <header id="header" class="header">
    <div class="header-nav">
      <a class="header-nav__logo" @click="scroll('fv')">
        <img src="@/assets/img/pg_logo.png" alt="PROJECT GROUP株式会社" />
      </a>
      <div class="header-nav__anchors">
        <a @click="scroll('service')" v-text="`サービスの特徴`" />
        <a @click="scroll('voice')" v-text="`導入事例`" />
        <a @click="scroll('plan')" v-text="`プラン一覧`" />
        <a @click="scroll('flow')" v-text="`ご利用の流れ`" />
        <a @click="scroll('question')" v-text="`よくあるご質問`" />
      </div>
      <div class="header-nav__btns">
        <a class="cta" @click="emit('toggleDialogContact', true)" v-text="`まずは無料相談`" />
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
  .header {
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    background-color: #FFF;
    z-index: 1000;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .3);
    &-nav {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 8px 4vw;
      // max-width: 1920px;
      // margin: auto 0;
      position: relative;
      &__logo {
        cursor: pointer;
        transition: .3s;
        height: 30px;
        margin: auto 0;
        &:hover {
          opacity: 0.7;
        }
        img {
          height: 100%;
          width: auto;
          // @include sp {
          //   width: 4;
          //   height: auto;
          // }
        }
      }
      &__anchors {
        display: flex;
        justify-content: space-around;
        padding: 10px;
        @include tb {
          display: none;
        }
        @include sp {
          display: none;
        }
        a {
          color: #565656;
          font-weight: bold;
          font-size: 14px;
          margin-left: 2vw;
          cursor: pointer;
          position: relative;
          &::after {
            position: absolute;
            bottom: -4px;
            left: 0;
            content: '';
            width: 100%;
            height: 2px;
            background: #454545;
            transform: scale(0, 1);
            transform-origin: left top;
            transition: transform .3s;
          }
          &:hover::after {
            transform: scale(1, 1);
          }
        }
      }
      &__btns {
        position: absolute;
        right: 4vw;
        top: 0;
        bottom: 0;
        display: flex;
        // align-self: flex-end;
        justify-content: space-around;
        padding: 9px 0;
        a {
          display: block;
          color: #FFF;
          font-weight: bold;
          padding: 0 28px;
          height: 42px;
          line-height: 40px;
          font-size: 17px;
          cursor: pointer;
          border: #FFF solid 1px;
          border-radius: 100px;
          box-shadow: 0 3px 3px 0 rgba(0, 0, 0, .3);
          background: linear-gradient(270deg, #4800A3 22.95%, #8008F9 83.18%);
          transition: .3s;
          @include sp {
            font-size: 15px;
            padding: 0 12px;
          }
          &:hover {
            transform: scale(1.03, 1.03);
            background: linear-gradient(0deg, #4800A3 22.95%, #8008F9 83.18%);
          }
        }
      }
    }
  }
</style>