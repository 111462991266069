<script setup>
  import { reactive, defineEmits } from 'vue'
  import CtaBtn from './CtaBtn.vue';
  const emit = defineEmits(['toggleDialogContact'])
  const list = reactive({
    name: ['ミニマム', 'ライト', 'スタンダード'],
    sub: ['お手軽に移行のみ済ませたい方', '重要指標のみ解析したい方', '多角的な視点から解析したい方'],
    price: ['50,000', '200,000', '400,000'],
    items: [
      { title: 'アカウント開設/計測タグ発行', detail: 'Googleアナリティクスのアカウントを開設し、計測を始められるよう設定します。' },
      { title: '内部トラフィック除外', detail: '社内メンバーなどの関係者によるアクセスは解析においてノイズとなってしまうため、計測対象に含めないよう設定する必要があります。' },
      { title: 'クロスドメイン設定', detail: 'Webサイトが複数ドメインにまたがっている場合、それらを横断して解析できるよう設定します。' },
      { title: 'コンバージョン設定', detail: '「会員登録」「問合せ」「購入」など、Webサイト内の目標を定義し、重要指標として解析できるよう設定します。' },
      { title: '探索レポート設定', detail: '「探索レポート」では、基本レポートでは確認できない詳細なデータを解析できます。拡張性も高く自由にカスタマイズ可能ですが、設定には高度なノウハウが必要です。' },
      { title: 'オーディエンス設定', detail: '特定の条件ごとにユーザーをグルーピングします。Google広告のターゲットとしても活用できます。' },
      { title: 'ユーザープロパティ設定', detail: 'たとえば会員と非会員とでは、Webサイト内での行動がまるで違います。このようなユーザーの属性ごとに解析することで、全体数値だけではわからない事実を明らかにできます。' },
      { title: 'ページ名設定', detail: 'ページビューの解析は重要ですが、URL単位での解析では不便なことも多いです。ページの種類ごとに適切な名称を設定することで、より効率的な解析が可能となります。' },
      { title: 'クリックイベント設定', detail: 'ユーザーがWebサイト内のどこをクリックをしたのか解析できるよう設定します。「フォームのどの項目で離脱しているか」なども一目瞭然です。' },
    ],
  })
</script>

<template>
  <section id="plan" class="plan">
    <div class="wrap">
      <h2>お客様のニーズに合わせて<br class="middleDevice" />プランを選択できます</h2>
      <div class="plan-contents">
        <div v-for="(name, i) in list.name" class="plan-content" :key="i">
          <p class="plan-name" v-text="`${name}プラン`" />
          <div class="plan-box">
            <p class="plan-sub" v-text="`${list.sub[i]}におすすめ`" />
            <p class="plan-price" v-text="`￥${list.price[i]}~`" />
            <ul class="plan-items">
              <li v-for="(item, n) in list.items" class="plan-item" :class="(n < (i+1)*3) ? 'active' : 'disabled'" :key="n">
                <div>{{ item.title }}</div>
                <span>{{ item.detail }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="under">
        <p class="under-text">
          ECサイトを運営している方向けに<br />
          <span>Eコマース設定</span>にも対応しております。<br />
          お気軽にご相談ください。
        </p>
        <div class="under-img">
          <img src="@/assets/img/v2/plan_img.jpg" />
        </div>
      </div>
      <CtaBtn @click="emit('toggleDialogContact', true)" text="最適なプランを相談する" />
    </div>
  </section>
</template>

<style lang="scss" scoped>

  .middleDevice {
    display: none;
    @include middleDevice {
      display: inline;
    }
    @include sp {
      display: inline;
    }
  }

  .plan {
    padding-bottom: 100px;
    @include sp {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    h2 {
      margin-bottom: 100px;
      @include middleDevice {
        line-height: 1.6;
      }
      @include sp {
        line-height: 1.6;
      }
    }
    &-contents {
      display: flex;
      gap: 22px;
      @include tb {
        flex-direction: column;
        gap: 100px;
      }
      @include sp {
        flex-direction: column;
        gap: 100px;
      }
    }
    &-content {
      width: calc(100% / 3);
      box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.25);
      border: #000 solid 6px;
      padding: 48px 20px 28px;
      position: relative;
      z-index: 2;
      @include tb {
        width: 100%;
      }
      @include sp {
        width: 100%;
        padding: 48px 12px 28px;
      }
      &::after {
        content: '03';
        position: absolute;
        right: -14px;
        top: -90px;
        font-size: 80px;
        z-index: -1;
        // font-family: "M PLUS 1p";
        color: #F1F1F1;
        font-weight: bold;
        @include tb {
          right: -34px;
        }
      }
      &:nth-of-type(1) {
        &::after {
          content: '01';
        }
        // .plan-items {
        //   .plan-item {
        //     &:nth-of-type(n + 4) {
        //       color: #ccc;
        //       &::before {
        //         border-color: #ccc;
        //       }
        //       &::after {
        //         display: none;
        //       }
        //     }
        //   }
        // }
      }
      &:nth-of-type(2) {
        &::after {
          content: '02';
        }
        // .plan-items {
        //   .plan-item {
        //     &:nth-of-type(n + 7) {
        //       color: #ccc;
        //       &::before {
        //         border-color: #ccc;
        //       }
        //       &::after {
        //         display: none;
        //       }
        //     }
        //   }
        // }
      }
    }
    &-name {
      display: block;
      position: absolute;
      top: -26px;
      left: -21px;
      width: 256px;
      font-size: 18px;
      padding: 10px 0;
      color: #FFF;
      background-color: $colorOrange;
      font-weight: bold;
      text-align: center;
      margin: 0;
      line-height: 1.6;
      @include sp {
        left: -15px;
      }
      &::after {
        position: absolute;
        content: "";
        top: 100%;
        left: 0;
        border: none;
        border-bottom: solid 12px transparent;
        border-right: solid 15px #ED0000;
        @include sp {
          border-bottom-width: 7px;
          border-right-width: 9px;
        }
      }
    }
    &-sub {
      font-size: 13px;
      text-align: center;
      margin: 0;
      font-weight: bold;
    }
    &-price {
      font-size: 40px;
      font-weight: bold;
      line-height: 1.6;
      color: $colorOrange;
      margin: 4px 0;
      text-align: center;
      border-bottom: $colorOrange solid 1px;
    }
    &-items {
      margin: 10px 0 0;
      padding: 0;
      list-style: none;
      .plan-item {
        padding: 10px 0 12px 35px;
        font-size: 16px;
        font-weight: bold;
        border-bottom: #F1F1F1 solid 1px;
        position: relative;
        @include sp {
          padding: 10px 0 12px 30px;
          font-size: 14px;
        }
        &::before {
          content: '';
          width: 20px;
          height: 20px;
          border: #1A1A1A solid 1px;
          border-radius: 2px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          @include sp {
            width: 16px;
            height: 16px;
          }
        }
        &::after {
          content: '';
          position: absolute;
          top: 10px;
          left: 4px;
          width: 18px;
          height: 8px;
          border-left: 4px solid $colorRed;
          border-bottom: 4px solid $colorRed;
          transform: rotate(-45deg);
          @include sp {
            width: 16px;
            height: 6px;
          }
        }
        &.disabled {
          color: #ccc;
          &::before {
            border-color: #ccc;
          }
          &::after {
            display: none;
          }
        }
        span {
          cursor: none;
          pointer-events: none;
          position: absolute;
          opacity: 0;
          top: 60px;
          left: -40px;
          right: -36px;
          max-width: 500px;
          z-index: 10;
          transition: .3s;
          background-color: $colorYellow;
          // color: #fff;
          // font-weight: 100;
          font-size: 12px;
          line-height: 1.4;
          border: #666 solid 2px;
          border-radius: 8px;
          padding: 10px;
          &::before {
            content: "";
            position: absolute;
            top: -16px;
            left: 50%;
            margin-left: -10px;
            border: 8px solid transparent;
            border-bottom-color: $colorYellow;
            z-index: 12;
          }
          &::after {
            content: "";
            position: absolute;
            top: -20px;
            left: 50%;
            margin-left: -11px;
            border: 9px solid transparent;
            border-bottom-color: #666;
            z-index: 11;
          }
        }
        &.active {
          cursor: pointer;
          div {
            position: relative;
            &::after {
              position: absolute;
              bottom: -5px;
              left: 0;
              content: '';
              width: 100%;
              height: 2px;
              background: #666;
              transform: scale(0, 1);
              transform-origin: left top;
              transition: transform .3s;
            }
          }
          &:hover {
            div::after {
              transform: scale(1, 1);
            }
            span {
              opacity: 1;
              top: 44px;
            }
          }
        }
      }
    }
    .under {
      display: flex;
      align-items: center;
      margin: 50px 0 0;
      @include sp {
        margin: 36px 0 0;
        flex-direction: column;
        text-align: center;
      }
      &-text {
        margin: 0;
        width: 45%;
        border-left: $colorOrange solid 20px;
        padding: 0 0 0 20px;
        font-size: 24px;
        line-height: 1.6;
        @include tb {
          font-size: 2vw;
          border-width: 1.6vw;
          padding: 0 0 0 1.8vw;
        }
        @include sp {
          font-size: 16px;
          border-width: 14px;
          width: auto;
          display: inline-block;
          text-align: left;
        }
        span {
          color: $colorOrange;
          font-weight: bold;
        }
      }
      &-img {
        flex: 1;
        @include sp {
          margin-top: 60px;
        }
      }
    }
  }
</style>