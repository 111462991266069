<script setup>
  import { reactive } from 'vue'

  const list = reactive({
    title: ['豊富な<span>ノウハウ</span>', '状況に応じた<span>対応力</span>', '幅の広い<span>提案力</span>'],
    body: [
      'GoogleAnalyticsを用いた<br />CVR改善サービスを展開<br />UA/GA4の導入・運用支援の実績多数',
      'エンジニアリングもできる<br />マーケターがサポート対応する<br />ため使用感のイメージがある',
      'サイト状況に適した設定が可能<br />GA4の導入支援だけでなく、<br />運用までサポートが可能'
    ]
  })
</script>

<template>
  <section id="service" class="service">
    <div class="wrap">
      <h2>
        <img src="@/assets/img/service_logo.png" />
        <span>PROJECT GROUP</span>の<br class="middleDevice" />GA4導入支援サービスなら
      </h2>
      <div class="service-contents">
        <div v-for="(title, i) in list.title" class="service-content" :key="i">
          <div class="circle">
            <div class="circle-inner">
              <span class="circle-num" v-text="`0${i+1}`" />
              <h3 v-html="title" />
              <div class="circle-img">
                <img :src="require(`@/assets/img/service_illustration0${i+1}.png`)" />
              </div>
            </div>
          </div>
          <p class="body" v-html="list.body[i]" />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>

  .middleDevice {
    display: none;
    @include middleDevice {
      display: inline;
    }
    @include sp {
      display: inline;
    }
  }

  .service {
    padding-bottom: 100px;
    background-image: url('./../../assets/img/service_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    @include sp {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    h2 {
      margin-bottom: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include middleDevice {
        display: block;
        line-height: 1.6;
      }
      @include sp {
        display: block;
        line-height: 1.6;
        margin-bottom: 50px;
      }

      img {
        width: 44px;
        margin-right: 10px;
        @include middleDevice {
          display: block;
          margin: 0 auto 10px;
        }
        @include sp {
          display: block;
          margin: 0 auto 10px;
        }
      }
      span {
        color: $colorRed;
      }
    }
    &-contents {
      display: flex;
      gap: 50px;
      @include tb {
        gap: 15px;
      }
      @include middleDevice {
        flex-direction: column;
        gap: 50px;
      }
      @include sp {
        flex-direction: column;
        gap: 50px;
      }
    }
    &-content {
      width: calc(100% / 3);
      @include middleDevice {
        width: 290px;
        margin: auto;
      }
      @include sp {
        width: 290px;
        margin: auto;
      }
      .circle {
        width: 100%;
        border-radius: 50%;
        background-color: #FFF;
        box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.25);
        border: 25px solid #B1D1E3;
        position: relative;
        @include tb {
          border-width: 15px;
        }
        @include middleDevice {
          border-width: 25px;
        }
        &::before {
          content: '';
          display: block;
          padding-top: 100%;
        }
        &-inner {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          text-align: center;
          padding: 20px 0;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
        &-num {
          font-size: 40px;
          font-weight: bold;
          @include tb {
            font-size: 4.4vw;
          }
          @include middleDevice {
            font-size: 40px;
          }
        }
        h3 {
          margin: 15px 0 0;
          font-size: 20px;
          font-weight: bold;
          line-height: 1;
          @include tb {
            font-size: 2.2vw;
            margin-top: 1.5vw;
          }
          @include middleDevice {
            font-size: 20px;
            margin-top: 15px;
          }
          :deep(span) {
            display: block;
            text-align: center;
            font-size: 30px;
            margin-top: 10px;
            @include tb {
              font-size: 3.4vw;
              margin-top: 1vw;
            }
            @include middleDevice {
              font-size: 30px;
              margin-top: 10px;
            }
          }
        }
        &-img {
          margin-top: 20px;
          @include tb {
            margin-top: 2vw;
          }
          @include middleDevice {
            margin-top: 20px;
          }
          img {
            width: 60px;
            @include tb {
              width: 6vw;
            }
            @include middleDevice {
              width: 60px;
            }
          }
        }
      }
      .body {
        margin: 55px 0 0;
        text-align: center;
        line-height: 1.6;
        font-size: 16px;
        font-weight: bold;
        @include tb {
          font-size: 1.6vw;
          margin-top: 3vw;
        }
        @include middleDevice {
          font-size: 16px;
          margin-top: 40px;
        }
      }
      &:nth-of-type(2) {
        .circle {
          border-color: #FFDC8D;
        }
      }
      &:nth-of-type(3) {
        .circle {
          border-color: #F2BAA9;
        }
      }
    }
  }

</style>